<template>
    <div class="pd20x flex1">
        <div class="contentBox h100pct ofA">
            <div class="filter" style="padding:10px 20px">
                <div>
                    <label>选择学科：</label>
                    <a-select v-model="filter.subjectid" style="width: 120px" @change="onChange" class="mr10px" placeholder="请选择学科">
                        <a-select-option :value="0">
                            所有学科
                        </a-select-option>
                        <a-select-option v-for="item in options" :key="item.id" :value="item.id">
                            {{item.name}}
                        </a-select-option>
                    </a-select>
                    <label>搜索：</label>
                    <a-input-search v-model="filter.keywords" placeholder="搜索学科名或用户昵称" style="width: 300px" @search="()=>{pagination.page = 1;getData()}" class="mr10px"/>
                </div>
            </div>
            <a-table
                    :columns="columns"
                    :data-source="data"
                    :pagination="pagination"
                    :rowKey="'id'"
                    @change="changePage"
            >
                <template slot="orderid" slot-scope="text, record">
                    {{record.orderid}}
                </template>
                <template slot="classBagname" slot-scope="text, record">
                    <div class="csP" @click="routerChange('学科详情', '/exam/classDetail', {id:record.id})">
                        <div>{{record.name}}</div>
                    </div>
                </template>
                <template slot="classNickname" slot-scope="text, record">
                    <div class="csP" @click="routerChange('课程包详情', '/exam/classDetail', {id:record.id})">
                        <a-avatar icon="user" :src="record.headimgurl"/> <div>{{record.nickname}}</div>
                    </div>
                </template>
                <template slot="statusList" slot-scope="text, record">
                    {{record.status == 0 ? '待发货' : '已发货'}}
                </template>
                <template slot="create_time" slot-scope="text, record">
                    {{formatDate(record.create_time*1000)}}
                </template>
                <template slot="send_time" slot-scope="text, record">
                    {{record.send_time>0 ? formatDate(record.send_time*1000) : '---'}}
                </template>
                <template slot="orderno" slot-scope="text">
                    {{text}}
                </template>
                <template slot="operation" slot-scope="text, record">
                    <a-button type="primary" ghost style="margin-right:10px" @click="sendModel(record.orderid,record.nickname)" v-if="record.status == 0">发货</a-button>
                    <a-button type="primary" style="margin-right:10px" @click="deleteItem(record.id)">删除</a-button>
                </template>
            </a-table>
        </div>
        <a-modal v-model="showModel" :title="modelTitle" ok-text="确认" cancel-text="取消" @ok="hideModal">
            <a-input v-model="modelSend"  placeholder="输入发货单号" />
        </a-modal>
    </div>
</template>
<script>
    const columns = [
        { title: '申请单号', dataIndex: 'orderid', align:'center' },
        { title: '学科名称', dataIndex: 'name', scopedSlots: { customRender: 'classBagname' }, align:'center' },
        { title: '申请用户', dataIndex: 'nickname', scopedSlots: { customRender: 'classNickname' }, align:'center' },
        { title: '订单状态', dataIndex: 'status', scopedSlots: { customRender: 'statusList' }, align:'center'},
        { title: '申请时间', dataIndex: 'create_time', scopedSlots: { customRender: 'create_time' },align:'center' },
        { title: '发货时间', dataIndex: 'send_time', scopedSlots: { customRender: 'send_time' },align:'center' },
        { title: '发货单号', dataIndex: 'orderno', align:'center'},
        { title: '操作', dataIndex: 'operation', scopedSlots: { customRender: 'operation' }, },
    ];
    import {formatDate} from '@/libs/moment'
    import * as http from '@/libs/analysis'
    import { routerlink } from '@/mixins/routerlink'
    export default {
        name: 'purchase',
        mixins: [routerlink],
        data(){
            return{
                modelSend : '',
                modelOrder : '',
                modelTitle : '',
                showModel : false,
                options: [],
                columns,
                filter:{
                    subjectid: 0,
                    keywords:''
                },
                pagination:{
                    page: 1,
                    size: '15',
                    pageSize: 15
                },
                allSubject:[],
                data: [],
                areaval: '',
            }
        },
        mounted(){
            this.$nextTick(()=>{
                Promise.all([this.getAllSubject()]).then(res=>{
                    this.getData()
                })
            })
        },
        methods:{
            formatDate,
            sendModel(orderid,name) {
                this.modelOrder = orderid;
                this.showModel = true;
                this.modelTitle = name + " 材料发货";
            },
            getData(){
                let request = {...this.filter,...this.pagination}
                if(this.filter.subjectid == 0){
                    request.subjectid = null
                }
                http.get_orderList(request).then(res=>{
                    const pagination = { ...this.pagination }
                    pagination.total = res.total;
                    this.data = res.data
                    this.pagination = pagination;

                }).catch(error=>{
                    console.log(error)
                })
            },
            getAllSubject(){
                return new Promise((resolve, reject)=>{
                    http.get_allSubject().then(res=>{
                        this.allSubject = res
                        this.options = res
                        resolve()
                    }).catch(error=>{
                        reject()
                    })
                })
            },
            handleChange(){

            },
            deleteItem(id){
                let _this = this
                this.$confirm({
                    title: '是否确定删除该条信息?',
                    centered: true,
                    onOk() {
                        http.delOrder({id}).then(res=>{
                            let {page, pagesize, total} = _this.pagination
                            if(page>1 && Number(page-1)* 15 == total - 1){
                                _this.pagination.page = Number(page-1)
                            }
                            _this.getData()
                        }).catch(error=>{
                            console.log(error)
                        })
                    },
                });
            },
            changePage({current}){
                this.pagination.page = current
                this.getData()
            },
            hideModal(){
                let request = {
                    orderid: this.modelOrder,
                    orderno: this.modelSend
                };
                http.sendOrder(request).then(res=>{
                    this.$message.success('发送成功');
                    this.getData();
                    this.showModel = false;
                    this.modelOrder = '';
                    this.modelSend = '';
                }).catch(error=>{
                    this.$message.success(error)
                    console.log(error)
                })
            },
            onChange(){

            },
        }
    }
</script>
<style lang="less" scoped>
    .imgWrap{
        width: 120px;
        height: 70px;
        overflow: hidden;
        border-radius: 4px;
        img{
            width: 100%;
        }
    }
</style>
